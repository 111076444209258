'use client';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import Carousel, { DELAY_TIME, DOT_POSITION } from '@fe-common-ui/Carousel';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import { useCallback } from 'react';
import AsideWrapper from '../AsideWrapper';
import FeatureReportCardToggle, { CardType } from './FeatureReportCardToggle';
import useFetchFeatureReport from './hooks/use-fetch-feature-report';

export enum GTMComponentSource {
  POPUP = 'popup'
}
interface FeatureReportProps {
  isShowIndicators?: boolean;
  cardHeight?: number;
  carouselHeight?: number;
  carouselWidth?: number;
  isOnlyCarousel?: boolean;
  isScroll?: boolean;
  type?: CardType;
  source?: GTMComponentSource;
  style?: React.CSSProperties;
}

const sectionAsideIcon = '/assets/icons/topicNews.svg';

const SIZE = {
  WIDTH: 300,
  HEIGHT: 288
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;

  && .footer {
    transform: translateY(-12px);
  }
`;

const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e2e8f1;
  gap: 16px;
  padding: 8px 16px 0 16px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    overflow: scroll;
  }
`;

const NewsFeatureReport = ({
  isShowIndicators = true,
  cardHeight,
  carouselHeight = SIZE.HEIGHT,
  carouselWidth = SIZE.WIDTH,
  isOnlyCarousel = false,
  isScroll = false,
  type = CardType.DEFAULT,
  source,
  style
}: FeatureReportProps) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`);
  const { data } = useFetchFeatureReport();
  const READ_MORE_GTM_EVENT = {
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
    [GTMEventKey.SECTION]: source === GTMComponentSource.POPUP ? 'News_Timed_Popup_專題' : '%p_專題',
    [GTMEventKey.CLICK_ITEM]: '看更多'
  };

  const onClick = useCallback(
    (title: string) => {
      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
        [GTMEventKey.SECTION]: source === GTMComponentSource.POPUP ? 'News_Timed_Popup_專題' : '%p_專題',
        [GTMEventKey.CLICK_ITEM]: title
      });
    },
    [source]
  );

  const mobileWrapper = () => {
    return (
      <ScrollWrapper className='scroll-wrapper'>
        {data?.map(({ cover = '', title = '', link = '', time = '' }, index: number) => (
          <FeatureReportCardToggle
            key={`${title}-${index}`}
            cover={cover}
            title={title}
            link={link}
            time={time}
            cardHeight={cardHeight}
            onClick={() => onClick(title)}
            type={type}
          />
        ))}
      </ScrollWrapper>
    );
  };

  const desktopWrapper = () => {
    return (
      <Container style={style}>
        <Carousel
          width={carouselWidth}
          height={carouselHeight}
          speed={DELAY_TIME}
          showIndicators={isShowIndicators}
          dotPosition={DOT_POSITION.FIXED}
        >
          {data?.map(({ cover = '', title = '', link = '', time = '' }, index: number) => (
            <FeatureReportCardToggle
              key={`${title}-${index}`}
              cover={cover}
              title={title}
              link={link}
              time={time}
              cardHeight={cardHeight}
              onClick={() => onClick(title)}
              type={type}
            />
          ))}
        </Carousel>
      </Container>
    );
  };

  if (isOnlyCarousel) {
    return isScroll ? mobileWrapper() : desktopWrapper();
  }

  return (
    <AsideWrapper
      title='專題報導'
      id='NewsFeatureReport'
      icon={sectionAsideIcon}
      readMoreLink='https://topics.cnyes.com'
      readMoreTarget='_self'
      gtmEvent={READ_MORE_GTM_EVENT}
    >
      {isDesktop ? desktopWrapper() : mobileWrapper()}
    </AsideWrapper>
  );
};

export default NewsFeatureReport;
