'use client';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable-next-line */
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Image from 'next/image';

export interface SectionAsideProps {
  title: string;
  icon?: string;
  RightContent?: React.ReactNode;
}

const Container = styled.div`
  color: black;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.55px;
  border-bottom: 3px solid #eaeef5;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    border-bottom: none;
    padding-right: 16px;
  }

  h3 {
    padding-left: 18px;
    text-align: left;
    flex: 1;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.55px;
    position: relative;

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      font-size: 22px;
      font-weight: 600;
      padding-left: 21px;
    }

    &::before {
      content: '';
      background-color: #e2e8f1;
      width: 2px;
      height: 20px;
      position: absolute;
      margin: 0 8px;
      left: 0;
      top: 0;

      @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
        background-color: #e03f19;
        width: 5px;
        height: 22px;
        position: absolute;
        left: -8px;
        top: 0;
      }
    }
  }

  .icon {
    display: block;

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      display: none;
    }
  }
`;

export function SectionAside({ icon, title, RightContent }: SectionAsideProps) {
  return (
    <Container className='section-aside-wrapper'>
      {icon && <Image src={icon} className='icon' alt='section icon' width={25} height={25} />}
      <h3>{title}</h3>
      {RightContent && RightContent}
    </Container>
  );
}

export default SectionAside;
