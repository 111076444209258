import { getStartEndData } from '@fe-common-utils/libs/time';
import type { GETTagCloudProps } from '@fe-news/api/news';
import {
  GETNews24hList,
  GETNewsCategories,
  GETNewsCategory,
  GETNewsListByTags,
  GETNotificationLectures,
  GETTagCloud,
  GetNotification
} from '@fe-news/api/news';
import { DEFAULT_EXPERT_TAGS, DEFAULT_NEWS_COUNTS_20 } from '@fe-news/constants/news/default-value';
import { getSymbol } from '@fe-news/utils/client/product';
import type { Word } from 'react-wordcloud';
import { fetchQuotesBySymbolsKeys } from './quotes';
import {
  formatNewsCategoryData,
  formatRecentTopicData,
  formateFeatureReportData,
  formateLectureData,
  formateNews24hListData,
  formateNewsIssueData,
  formateNotificationData,
  formateTwStockExpertData
} from './utils/format-data';

// 取得主編精選

const FIRST_INDEX = 0;

export const fetchNews24hList = async ({ limit }: { limit: number }): Promise<News24hDataType[]> => {
  try {
    const data = await GETNews24hList();

    const news = data.items.data ? formatNewsCategoryData(data.items.data.slice(0, limit)) : [];

    const symbols = news
      ?.map((newsInfo: NewsCategoryType) => getSymbol(newsInfo?.otherProduct?.[FIRST_INDEX] ?? ''))
      ?.filter(symbol => symbol) ?? [''];

    const markets = await fetchQuotesBySymbolsKeys(symbols);
    const response = formateNews24hListData(news, markets);

    return response;
  } catch (error) {
    console.error('[API Error]: fetchNews24hList', error);
    return [];
  }
};

// 專題報導

export const fetchFeatureReport = async ({ key = '' }: { key: string }): Promise<FeatureReportType[]> => {
  try {
    const response = await GETNewsCategories({ key: key });
    const data = formateFeatureReportData(response.items.project);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchFeatureReport', error);
    return [];
  }
};

// 熱門話題

export const fetchRecentTopic = async ({ kind, limit = DEFAULT_NEWS_COUNTS_20 }: GETTagCloudProps): Promise<Word[]> => {
  try {
    const response = await GETTagCloud({ kind, limit });

    const data = formatRecentTopicData(response.items);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchRecentTopic', error);
    return [];
  }
};

// 專家觀點

export const fetchTwStockExpert = async (): Promise<TwStockExpertProps[]> => {
  try {
    const startEndDate = getStartEndData(180);

    const requests = DEFAULT_EXPERT_TAGS.map(tag => {
      // 專家觀點新聞區，最後增加一個「永豐金證券」，但tag newslist API實際上要抓兩個tag combine: 永豐金證券＋趨勢分析
      const tagName = tag === '永豐金證券' ? '永豐金證券,趨勢分析' : tag;

      return GETNewsListByTags({
        q: tagName,
        page: 1,
        startAt: startEndDate.startAt,
        endAt: startEndDate.endAt
      });
    });

    const response = await Promise.all(requests);

    const data = formateTwStockExpertData(response);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchTwStockExpert', error);
    return [];
  }
};

// 議題新聞

type FetchNewsIssueProps = {
  category: string;
  limit: number;
};

export const fetchNewsIssue = async ({ category, limit }: FetchNewsIssueProps) => {
  try {
    const response = await GETNewsCategory({ catSlug: category, limit });

    const data = formateNewsIssueData(response.items.data || []);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchNewsIssue', error);
    return [];
  }
};

// 鉅亨講座
export const fetchNotificationLectures = async (id?: number): Promise<NotificationLecturesDataType[]> => {
  try {
    const response = await GETNotificationLectures(id);

    const data = formateLectureData(response.items.data);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchNotificationLectures', error);
    return [];
  }
};

export const fetchNotification = async (
  id?: number,
  channels?: number,
  messageType?: number
): Promise<NotificationLecturesDataType[]> => {
  try {
    const response = await GetNotification(id, channels, messageType);

    const data = formateNotificationData(response.items.data);

    return data;
  } catch (error) {
    console.error('[API Error]: fetchNotification', error);
    return [];
  }
};
