/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';

type CardThemeProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  imgWidth?: number | string;
  imgHeigth?: number | string;
  publishAt: number | string;
  target?: '_self' | '_blank';
};

const ListContainerBase = styled.li`
  list-style: none;
  border: 1px solid #e2e8f1;
  color: #383838;
  width: 264px;
  height: 256px;
  word-wrap: break-word;
  border-radius: 6px;
  background-color: white;
`;

const Content = styled.a`
  text-decoration: none;
  color: #383838;
  height: 100%;
  display: block;

  img {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    min-height: 64px;
    min-width: 90px;
  }

  p,
  time {
    padding: 0 16px;
  }

  p {
    margin: 0;
    line-height: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  time {
    font-size: 12px;
    color: #909090;
    letter-spacing: 0.5px;
    margin-top: 7px;
    height: 18px;
    display: flex;
    justify-content: flex-end;
  }
`;

const DefaultImage = styled.div`
  width: 100%;
  height: 148px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #eee;
`;

const CardTheme = ({
  title,
  newsId,
  coverSrc,
  imgWidth,
  imgHeigth,
  publishAt,
  target,
}: CardThemeProps) => {
  return (
    <ListContainerBase key={newsId}>
      <Content href={`/news/id/${newsId}`} target={target}>
        {coverSrc ? (
          <img alt="news cover" src={coverSrc} width={262} height={148} />
        ) : (
          <DefaultImage />
        )}
        <p>{title}</p>
        <time>{publishAt}</time>
      </Content>
    </ListContainerBase>
  );
};

export default CardTheme;
