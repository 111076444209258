import { QUOTES_CODE_MAPPING } from '@fe-common-utils/libs/quote';

export const getSymbol = (product?: string) => {
  if (!product) return '';
  const [market, code, type] = product.split(':');

  return `${market}:${code}:${type}`;
};

/**
 * @param quotes
 * @returns
 * @description 將quotes整理為以symbol作為key的物件方便查詢
 * e.g.
 * {
 *   HKS:03333:STOCK: {name: '中國恒大', ratio: -9.75, marketLink: null, symbol: 'HKS:03333:STOCK'},
 *   TWS:1101:STOCK: {name: '台泥', ratio: 0.47, marketLink: 'https://www.beta.cnyes.cool/twstock/1101', symbol: 'TWS:1101:STOCK'}
 * }
 */

export type SymbolQuote = {
  name: string;
  ratio: number;
  marketLink: string;
  symbol: string;
};

export const formatQuotes = (quotes: Quote[]): Record<string, SymbolQuote> => {
  const initial: Record<string, SymbolQuote> = {};

  return quotes.reduce((accumulator, quote) => {
    const symbol = quote[QUOTES_CODE_MAPPING.Symbol as keyof Quote] as string;
    const name = quote[QUOTES_CODE_MAPPING.ChineseName as keyof Quote] as string;
    const changePercent = quote[QUOTES_CODE_MAPPING.ChangePercent as keyof Quote] as number;
    const marketLink = quote[QUOTES_CODE_MAPPING.Url as keyof Quote] as string;

    accumulator[symbol] = { name, ratio: changePercent, marketLink, symbol };

    return accumulator;
  }, initial);
};

// 將所有新聞的symbols取出為字串陣列, e.g. ['HKS:03333:STOCK', 'TWS:1101:STOCK', ...]
export const pagesNewsSymbols = (news?: News[]) => {
  if (!news) return [];

  const set = new Set<string>();

  return news.reduce((accumulator, item) => {
    const symbols = item?.market?.map(market => market.symbol) || [];

    for (const symbol of symbols) accumulator.add(symbol);

    return accumulator;
  }, set);
};
