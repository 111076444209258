export const QUOTES_CODE_MAPPING = {
  Symbol: 0,
  Price: 6,
  ChineseName: 200009,
  StockCode: 200010 /* 股票代碼 */,
  ShortName: 200232,
  Change: 11,
  ChangePercent: 56,
  TradeTime: 200007 /* 交易時間 */,
  Url: 800043 /* 內頁連結 */,
  LastClose: 21,
  StockType: 200061 /* 股票類型 */,
  lastFormat: 220026 /** 最新價_V2 * - 220026 */,
  netChangeFormat: 220027 /** 漲跌_V2 * - 220027 */,
  ChangePercentV2: 220064, // 漲跌％_V2
  MarketStatus: 800041, // (0:收盤, 1:清盤, 2:開盤, 3:盤中暫停交易)
  Volume: 800001, // 成交量 non-formatted
  NonFormattedChartQuote: {
    Price: 6, // 最新價
    Change: 11, // 漲跌
    OpenPrice: 19, // 開盤
    LastClose: 21, // 現價
    ChangePercent: 56, // 漲跌％
    BusinessVolume: 200067, // 成交額
    HighPrice: 12, // 最高
    LowPrice: 13 // 最低
  },
  Forex: {
    Price: 200026, //最新價
    Change: 200027, //漲跌
    ChangePercent: 200044, //漲跌幅度
    MaxPrice: 200028, //最高價
    MinPrice: 200029, //最低價
    MouthRatio: 200040, //月漲幅
    QuarterRatio: 200038, //季漲幅
    HalfYearRatio: 200039, //半年漲幅
    YearAskHigh: 200042, //今年最高ASK
    YearAskLow: 200043 //今年最高ASK
  }
};
