import { fetchFeatureReport } from '@fe-news/actions/news';
import { FEATURE_REPORT_PROJECT_KEY } from '@fe-news/constants/news/default-value';
import { useQuery } from '@tanstack/react-query';

const useFetchFeatureReport = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['fetch-feature-report', FEATURE_REPORT_PROJECT_KEY],
    queryFn: () => fetchFeatureReport({ key: FEATURE_REPORT_PROJECT_KEY }),
    staleTime: 10 * 60 * 1000
  });

  return { data, isLoading: isFetching };
};

export default useFetchFeatureReport;
