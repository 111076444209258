/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
'use client';
import cx from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@linaria/react';

export const DELAY_TIME = 10000;
export const LEFT = 'left';
export const RIGHT = 'right';
export const DOT_POSITION = {
  FLOAT: 'float',
  FIXED: 'fixed'
};

const ARROW_LEFT = '/assets/icons/carousel/icon-slider-arrow-left-with-round.svg';
const ARROW_RIGHT = '/assets/icons/carousel/icon-slider-arrow-right-with-round.svg';

/**
 * @param {number} [width = 300] - 顯示寬度
 * @param {number} [height = 193] - 顯示高度
 * @param {boolean} [hasArrow = false] - 是否需要顯示左右箭頭
 * @param {boolean} [autoPlay = true] - 是否自動播放
 * @param {number} [selected = 0] - 指定從第幾個 Slide 開始播
 * @param {number}} [speed = 10000] - 切換速度(ms)，預設是 10 秒
 * @param {string}} [dotPosition = fixed] - 分頁按鈕對齊方式，共有二種 float | fixed
 * @param {function}} 完成切換的 Callback 處理
 */

enum IndicatorStyles {
  BAR = 'bar',
  PAGE = 'page'
}

interface CarouselProps {
  width?: number;
  height?: number;
  hasArrow?: boolean;
  autoPlay?: boolean;
  selected?: number;
  speed?: number;
  dotPosition?: string;
  onChange?: (index: number) => void;
  children: React.ReactNode;
  showIndicators?: boolean;
  indicatorsStyle?: string;
}

const CarouselContainer = styled.div`
  position: relative;

  .carousel {
    position: relative;
    overflow: hidden;

    &__body {
      height: 100%;

      &__item {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    &__arrows {
      position: absolute;
      z-index: 10;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);

      &__arrow {
        padding: 0;
        margin: 0 8px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        opacity: 0;
        transition: opacity ease-in-out 0.3s;
        font-size: 12px;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        position: absolute;
        display: inline-block;

        &:first-of-type {
          left: 0;
        }

        &:last-of-type {
          right: 0;
        }

        &--active {
          opacity: 1;
        }

        & > img {
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    position: relative;
    width: 100%;
    margin-top: 12px;

    &__indicators {
      text-align: center;
      line-height: 2px;

      &__indicator {
        list-style: none;
        display: inline-block;
        width: 12px;
        background-color: #b3c0ce;
        border: 2px solid #b3c0ce;
        border-radius: 2px;
        outline: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: width 0.5s ease-in-out;

        &:not(:last-of-type) {
          margin-right: 6px;
        }

        &--active {
          width: 24px;
          border: 2px solid #e03f19;
          background-color: #e03f19;
        }
      }
    }

    &.float {
      position: absolute;
      bottom: 8px;
    }
  }
`;

const PageIndicator = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  border-radius: 20px;
  border: solid 1px rgba(255, 255, 255, 0.7);
  background-color: rgba(28, 30, 47, 0.5);
  font-size: 14px;
  color: white;
`;

export const Carousel = ({
  width = 300,
  height = 232,
  hasArrow = true,
  autoPlay = true,
  selected = 1,
  speed = DELAY_TIME,
  dotPosition = DOT_POSITION.FLOAT,
  onChange,
  children,
  showIndicators = true,
  indicatorsStyle = IndicatorStyles.BAR
}: CarouselProps) => {
  const timerRef = useRef<number | null>();
  const [currentCarousel, setCurrentCarousel] = useState(0);
  const [animationStep, setAnimationStep] = useState(0);
  const [isShowArrow, setIsShowArrow] = useState(false);

  // 取得目前輪播物件個數
  const childrenCount = React.Children.count(children) || 0;

  /**
   * @description 更改目前循環播放的下一個座標
   * @param {string} direction 輪播方向 (left | right)
   */
  const handlerCarousel = (direction = RIGHT) => {
    const directionValue = direction === LEFT ? -1 : 1;

    // 座標不為 -1 或是目前不是最後一個的情況下，進行遞增或遞減
    if (currentCarousel % (childrenCount + 1) !== childrenCount && currentCarousel >= 0) {
      setAnimationStep(selected);
      // 當座標在邊界時，會取消動畫過場的秒數，當目前座標不是邊界時，要恢復動畫過場秒數，不然無法正確播放動畫
      setCurrentCarousel(prevState => {
        const current = prevState + directionValue;

        return current % (childrenCount + 1);
      });
    }
  };

  /**
   * @description 停止輪播
   */
  const stopCarousel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    timerRef.current && clearInterval(timerRef.current);
  };

  /**
   * @description 開始輪播
   */
  const startCarousel = () => {
    stopCarousel();

    timerRef.current = window.setInterval(() => {
      handlerCarousel();
    }, speed);
  };

  /**
   * @description 顯示/隱藏箭頭
   */
  const handleMouseEnter = () => {
    if (hasArrow) setIsShowArrow(true);
  };

  const handleMouseLeave = () => {
    if (hasArrow) setIsShowArrow(false);
  };

  /**
   * @description 監聽動畫結束
   */
  const handleTransitionEnd = () => {
    // 當輪播最後一個時，取消過場動畫，並將座標位置重設為 0
    if (currentCarousel % (childrenCount + 1) === childrenCount) {
      setAnimationStep(0);
      setCurrentCarousel(0);
      // 當輪播第一個時，取消過場動畫，並將座標位置設為最後一個
    } else if (currentCarousel < 0) {
      setAnimationStep(0);
      setCurrentCarousel(childrenCount - 1);
    }
  };

  /**
   * @description 設定點擊箭頭後，切換顯示對應方向的 Slide 內容
   *
   * @param {string} direction - 移動方向 (left | right)
   */
  const handleArrowClick = (direction: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    autoPlay && startCarousel();

    handlerCarousel(direction);
  };

  /**
   * @description 判斷導航小點點的 active 狀態
   */
  const getIndicatorsActive = (index: number) => {
    return (
      currentCarousel === index ||
      currentCarousel === index + childrenCount ||
      (currentCarousel < 0 && index === childrenCount - 1)
    );
  };

  /**
   * @description 點擊指定輪播項目切換
   */
  const onGoToCarousel = (index: number) => {
    setCurrentCarousel(index);
  };

  /**
   * @description 開始執行
   */
  useEffect(() => {
    setAnimationStep(selected);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    autoPlay && startCarousel();

    if (!autoPlay) {
      stopCarousel();
    } else {
      startCarousel();
    }

    return () => {
      stopCarousel();
    };
  }, [autoPlay]);

  useEffect(() => {
    if (onChange) {
      const index = currentCarousel === childrenCount || currentCarousel === -1 ? 0 : currentCarousel;

      onChange(index);
    }
  }, [currentCarousel]);

  const totalCount = childrenCount + 2;
  const containerWidth = width.toString().includes('%') ? width : `${width}px`;
  const containerHeight = height.toString().includes('%') ? height : `${height}px`;

  const carouselStyle = { width: containerWidth, height: containerHeight };
  const transitionStyle = {
    transition: `transform ${animationStep}s`,
    width: `${totalCount * 100}%`,
    height: containerHeight,
    transform: `translateX(${(-100 / totalCount) * (currentCarousel + 1)}%)`
  };
  const firstChild = children && Array.isArray(children) ? children[0] : null;
  const lastChild = children && Array.isArray(children) ? children[children.length - 1] : null;

  /**
   * @description 繪制箭頭
   *
   * @param {string} direction - 移動方向 (left | right)
   */
  const renderCarouselArrow = (direction = LEFT) => {
    const arrowIcon = direction === LEFT ? ARROW_LEFT : ARROW_RIGHT;

    return (
      <div
        className={cx('carousel__arrows__arrow', {
          'carousel__arrows__arrow--active': isShowArrow
        })}
        onClick={() => handleArrowClick(direction)}
      >
        <img className={`carousel__arrows__${arrowIcon}`} src={arrowIcon} alt={`${direction} arrow`} />
      </div>
    );
  };

  /**
   * @description 繪製輪播項目
   *
   * @param {ReactNode} child - 輪播項目
   * @param {number} index - 索引值
   */
  const renderCarouselItem = (child: React.ReactNode, index = 0) => {
    if (!child) return null;

    const carouselItemStyle = {
      width: `${Math.round(10000 / totalCount) / 100}%`
    };

    return (
      <div className={cx('carousel__body__item')} style={carouselItemStyle} key={`carousel_item_${index}`}>
        {child}
      </div>
    );
  };

  const renderIndicators = () => {
    if (!children || !showIndicators) return null;

    if (indicatorsStyle === IndicatorStyles.PAGE) {
      const current =
        (currentCarousel >= childrenCount ? (currentCarousel + 1) % childrenCount : currentCarousel + 1) || 0;

      return (
        <PageIndicator>
          {current} / {childrenCount}
        </PageIndicator>
      );
    }

    return (
      <div className={cx('footer', { float: dotPosition === DOT_POSITION.FLOAT })}>
        <ul className={cx('footer__indicators')}>
          {Array.isArray(children) &&
            children.map((_: unknown, index: number) => {
              const isActive = getIndicatorsActive(index);

              return (
                <li
                  key={`carousel_indicator_${index}`}
                  onClick={() => onGoToCarousel(index)}
                  className={cx('footer__indicators__indicator', {
                    'footer__indicators__indicator--active': isActive
                  })}
                />
              );
            })}
        </ul>
      </div>
    );
  };

  return (
    <CarouselContainer style={carouselStyle}>
      <div className={cx('carousel')} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={cx('carousel__body')} onTransitionEnd={handleTransitionEnd} style={transitionStyle}>
          {renderCarouselItem(lastChild, 0)}
          {children && Array.isArray(children) && children.map((item, index) => renderCarouselItem(item, index + 1))}
          {renderCarouselItem(firstChild, totalCount)}
        </div>
        <div className={cx('carousel__arrows')}>
          {hasArrow && renderCarouselArrow(LEFT)}
          {hasArrow && renderCarouselArrow(RIGHT)}
        </div>
      </div>
      {renderIndicators()}
    </CarouselContainer>
  );
};

export default Carousel;
