import type { Word } from 'react-wordcloud';
import { postTimeDisplayFactory } from '@fe-common-utils/libs/time';
import { LectureNotification } from './type';

export const NEWS_24H_NEEDED = 3;

export const FEATURE_REPORT_LIMIT = 3;

export const TW_STOCK_SLUG = 'project';

export const FEATURE_REPORT_PROJECT_KEY = `${TW_STOCK_SLUG}:${FEATURE_REPORT_LIMIT}`;

export const DEFAULT_NEWS_COUNTS_20 = 20;

export const DEFAULT_NEWS_COUNTS_30 = 30;

export const MAX_TAG_WORD_LENGTH = 5;

// 熱門話題

const DEFAULT_RESULT_VALUE = {
  value: 0,
  text: '',
  original: ''
};

export const DEFAULT_RECENT_TOPIC_VALUE_ARRAY = Array.from<Word>({ length: DEFAULT_NEWS_COUNTS_30 }).fill(
  DEFAULT_RESULT_VALUE
);

export const DEFAULT_RECENT_TOPIC_TAB = 1;

export const DEFAULT_RECENT_TOPIC_LIMIT = 30;

// 專家觀點

const DEFAULT_EXPERT_NUMBER = 6;

export const DEFAULT_EXPERT_TAGS = ['大來投顧', '永誠投顧', '摩爾投顧', '亨達投顧', '永豐金證券', '倫元投顧'];

export const DEFAULT_EXPERT_VALUE = {
  newsId: 0,
  title: '--',
  content: '--',
  keyword: [''],
  publishAt: postTimeDisplayFactory(Date.now())?.display ?? '--',
  url: '',
  coverSrc: '',
  tag: '   '
};

export const DEFAULT_EXPERT_VALUE_ARRAY = Array.from<TwStockExpertProps>({ length: DEFAULT_EXPERT_NUMBER }).fill(
  DEFAULT_EXPERT_VALUE
);

// 議題新聞

export const NEWS_ISSUE_CATEGORY_NAME = 'topTopics';

export const NEWS_ISSUE_LIMIT = 4;

const DEFAULT_NEWS_ISSUE_VALUE: IssueNewsType = {
  newsId: 0,
  title: '',
  time: postTimeDisplayFactory(Date.now())?.display ?? '--',
  categoryName: '',
  src: '',
  topicTitle: '',
  url: ''
};

export const DEFAULT_NEWS_ISSUE_VALUE_ARRAY = Array.from<IssueNewsType>({ length: NEWS_ISSUE_LIMIT }).fill(
  DEFAULT_NEWS_ISSUE_VALUE
);

// 鉅亨講座

export const ANUE_LECTURE_LIMIT = 4;

const DEFAULT_ANUE_LECTURE_VALUE: LectureNotification = {
  id: 0,
  title: '--',
  highlight: 0,
  link: '',
  year: '--',
  mouth: '--',
  date: '--',
  day: '--',
  time: '--'
};

export const DEFAULT_ANUE_LECTURE_VALUE_ARRAY = Array.from<LectureNotification>({ length: ANUE_LECTURE_LIMIT }).fill(
  DEFAULT_ANUE_LECTURE_VALUE
);
