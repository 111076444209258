import {
  postTimeDisplayFactory,
  timeDisplayFactory,
  getMonthDaysFromPHPTimestamp,
  timeFormat
} from '@fe-common-utils/libs/time';
import { QUOTES_CODE_MAPPING } from '@fe-common-utils/libs/quote';
import {
  FEATURE_REPORT_LIMIT,
  MAX_TAG_WORD_LENGTH,
  DEFAULT_RECENT_TOPIC_VALUE_ARRAY,
  DEFAULT_EXPERT_VALUE_ARRAY,
  DEFAULT_EXPERT_VALUE,
  DEFAULT_EXPERT_TAGS,
  DEFAULT_NEWS_ISSUE_VALUE_ARRAY,
  ANUE_LECTURE_LIMIT,
  DEFAULT_ANUE_LECTURE_VALUE_ARRAY
} from '@fe-news/constants/news/default-value';
import type {
  GETNewsListByTagsResponse,
  ProjectType,
  TagCloudData,
  LectureNotification
} from '@fe-news/constants/news/type';
import type { Word } from 'react-wordcloud';
import { arrayGroup, arrayShuffle } from '@fe-news/components/LogoWallCarousel/utils/utils';
import { PopTopicListData, PopularTopicType } from '@fe-news/constants/pop-topic/type';

// format 主編精選 data

const FIRST_INDEX = 0;
const MAX_TAG_NUMBER = 3;

export const formatNewsCategoryData = (news: NewsCategoryType[]) => {
  return news.map(newsInfo => {
    return { ...newsInfo, market: newsInfo?.otherProduct?.[FIRST_INDEX] ?? '' };
  });
};

export const formateNews24hListData = (news: NewsCategoryType[], markets: Quote[]): News24hDataType[] => {
  return (
    news?.map(({ newsId = 0, publishAt, title = '', coverSrc, categoryName = '', keyword, market = '' }) => {
      const source = coverSrc?.s?.src;
      const time = postTimeDisplayFactory(publishAt * 1000)?.display ?? '--';
      const url = `/news/id/${newsId}`;
      const selectIndex = markets?.findIndex(product => market.includes(product?.[FIRST_INDEX]));
      const marketQuote = markets?.[selectIndex];
      const name = marketQuote?.[QUOTES_CODE_MAPPING.ChineseName as keyof Quote] as string;
      const changePercent = marketQuote?.[QUOTES_CODE_MAPPING.ChangePercent as keyof Quote] as number;
      const marketLink = marketQuote?.[QUOTES_CODE_MAPPING.Url as keyof Quote] as string;
      const symbol = marketQuote?.[QUOTES_CODE_MAPPING.Symbol as keyof Quote] as number;

      return {
        newsId,
        url,
        time,
        title,
        src: source,
        categoryName,
        tags: keyword.slice(0, MAX_TAG_NUMBER),
        otherProduct: marketQuote ? [{ name, ratio: changePercent, marketLink, symbol }] : []
      };
    }) ?? []
  );
};

// format 專題報導 data

const featureReportDefaultValue = {
  id: 0,
  title: '',
  link: '',
  cover: '',
  time: ''
};

export const formateFeatureReportData = (data: ProjectType[]): FeatureReportType[] => {
  if (!data?.length)
    return Array.from({ length: FEATURE_REPORT_LIMIT }).fill(featureReportDefaultValue) as FeatureReportType[];

  // eslint-disable-next-line unicorn/prevent-abbreviations
  return data.map(({ id = 0, title = '', link = '', image = {}, createdAt = 0 }) => {
    const cover = image.l?.src ?? '';
    const time = timeDisplayFactory(Number(createdAt) * 1000, Date.now());

    return { id, title, link, cover, time };
  });
};

// format 熱門話題 data

export const formatRecentTopicData = (data: TagCloudData[]): Word[] => {
  if (data.length === 0) return DEFAULT_RECENT_TOPIC_VALUE_ARRAY;

  return data.map(({ rank, tag = '' }) => {
    return {
      value: Number(rank),
      // 當文字字數過多，同時又是最大的字，會導致文字雲排版錯誤，把整個畫布撐開，導致所有的字變得很小
      // 因應解法，將超過 6 個字以上的 tag，只顯示前 5 個字
      text: tag.length > MAX_TAG_WORD_LENGTH ? `${tag.slice(0, MAX_TAG_WORD_LENGTH)}...` : tag,
      // 保留原始 tag
      original: tag
    };
  });
};

// format 專家觀點 data
export const formateTwStockExpertData = (data: GETNewsListByTagsResponse[]): TwStockExpertProps[] => {
  if (data.length === 0) return DEFAULT_EXPERT_VALUE_ARRAY;

  return data.map((news: GETNewsListByTagsResponse, index: number) => {
    const info = news.items.data?.[FIRST_INDEX] ?? DEFAULT_EXPERT_VALUE;
    const time = postTimeDisplayFactory(Number(info.publishAt) * 1000)?.display ?? '--';
    const url = `/news/id/${info?.newsId}`;

    return {
      title: info.title,
      newsId: info.newsId,
      publishAt: time,
      tag: DEFAULT_EXPERT_TAGS[index],
      url,
      coverSrc: ''
    };
  });
};

// format 下單中心 data

export const formateLogoWallData = (sourceArray: LogoWallProps[]) => {
  if (sourceArray.length > 0) {
    const radomArray = arrayShuffle(sourceArray);
    let index = 0;

    while (radomArray.length < 12) {
      radomArray.push(radomArray[index]);
      index += 1;
    }

    return arrayGroup(radomArray, 4);
  }

  return [Array.from({ length: 4 }).fill(0), Array.from({ length: 4 }).fill(0), Array.from({ length: 4 }).fill(0)];
};

// format 議題新聞 data

export const formateNewsIssueData = (news: IssueNewsDataType[]): IssueNewsType[] => {
  if (!news?.length) return DEFAULT_NEWS_ISSUE_VALUE_ARRAY;

  return news.map(({ newsId = 0, publishAt, title = '', coverSrc, categoryName = '', topic }) => {
    const source = coverSrc?.s?.src;
    const time = postTimeDisplayFactory(publishAt * 1000)?.display ?? '--';
    const url = `/news/id/${newsId}`;

    return {
      newsId,
      url,
      time,
      title,
      src: source,
      categoryName,
      topicTitle: topic?.title ?? ''
    };
  });
};

// format 鉅亨講座 data
export const formateLectureData = (data: NotificationLecturesDataType[]): LectureNotification[] => {
  if (data.length === 0) return DEFAULT_ANUE_LECTURE_VALUE_ARRAY;

  return data
    .map(({ id = 0, title = '', startDatetime = 0, highlight = 0, link = '' }) => {
      const { common, day } = getMonthDaysFromPHPTimestamp(startDatetime);
      const [year = '0', mouth = '0', date = '0'] = common?.split('/') || [];
      const time = timeFormat(startDatetime, 'HH:mm');

      return {
        id,
        title,
        highlight,
        link,
        year,
        mouth,
        date,
        day,
        time
      };
    })
    .slice(0, ANUE_LECTURE_LIMIT);
};

export const formateNotificationData = (data: NotificationLecturesDataType[]): LectureNotification[] => {
  if (data.length === 0) return DEFAULT_ANUE_LECTURE_VALUE_ARRAY;

  return data
    .map(({ id, title, highlight, link, publishAt = 0 }) => {
      const { common, day } = getMonthDaysFromPHPTimestamp(publishAt);
      const [year = '0', mouth = '0', date = '0'] = common?.split('/') || [];
      const time = timeFormat(publishAt, 'HH:mm');
      return { id, link, title, highlight, year, mouth, date, day, time };
    })
    .slice(0, ANUE_LECTURE_LIMIT);
};

// format 熱門時事列表 data

export const convertPopTopicDataToPopTopicList = (popularTopic: PopularTopicType): PopTopicListData => {
  const today = new Date();

  return {
    id: popularTopic.id,
    isOnline: popularTopic.isOnline,
    isPin: popularTopic.isPin,
    seq: popularTopic.seq,
    onlineAt: popularTopic.onlineAt,
    title: popularTopic.title,
    content: popularTopic.content,
    categories: popularTopic.categories,
    tagAnd: popularTopic.tagAnd,
    tagOr: popularTopic.tagOr,
    tagNot: popularTopic.tagNot,
    eventAt: popularTopic.eventAt,
    newsDays: 0,
    newsCount: 0,
    updateUserId: 0,
    createdAt: today.toDateString(),
    updatedAt: today.toDateString()
  };
};
