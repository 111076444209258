import { FeatureReportCard, FeatureReportListCard } from '@fe-common-ui/ListItem';
import { memo } from 'react';

export enum CardType {
  DEFAULT = 'default',
  INDEX_LIST = 'index-list'
}

interface FeatureReportProps {
  title: string;
  link: string;
  cardHeight?: number;
  cover: string;
  time: string;
  onClick?: () => void;
  type: CardType;
}

const FeatureReportCardToggle = ({ type = CardType.DEFAULT, ...props }: FeatureReportProps) => {
  if (type === CardType.DEFAULT) return <FeatureReportCard {...props} />;

  if (type === CardType.INDEX_LIST) return <FeatureReportListCard {...props} />;
};

export default memo(FeatureReportCardToggle);
