import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface FeatureReportProps {
  title: string;
  link: string;
  cardHeight?: number;
  cover: string;
  time: string;
  onClick?: () => void;
}

const IMAGE_HEIGHT = 172;

const ContentWrapper = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    min-width: 300px;
  }
`;

const CarouselItem = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? height : 274)}px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e8f1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const CoverWrapper = styled.div`
  width: 100%;
  min-height: ${IMAGE_HEIGHT}px;
  margin-top: -3px;

  :global(.footer) {
    margin-top: 0;
  }
`;

const NewsCover = styled.img`
  width: 100%;
  height: ${IMAGE_HEIGHT}px;
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 16px 7px 16px;
`;

const TitleContent = styled.p`
  width: 100%;
  height: 56px;
  font-size: 16px;
  line-height: 1.75;
  color: #383838;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TimeContent = styled.time`
  width: 99px;
  margin-top: 9px;
  font-size: 13px;
  letter-spacing: 1.06px;
  color: #848d97;
  text-align: right;
  align-self: flex-end;
`;

const FeatureReportCard = ({
  title = '',
  link = '',
  cardHeight,
  cover = '',
  time = '',
  onClick
}: FeatureReportProps) => {
  return (
    <ContentWrapper title={title} href={link} onClick={onClick}>
      <CarouselItem height={cardHeight}>
        {cover ? (
          <NewsCover src={cover} alt='news cover' title={title} />
        ) : (
          <CoverWrapper>
            <Skeleton height={IMAGE_HEIGHT} />
          </CoverWrapper>
        )}
        <TitleWrapper>
          <TitleContent>{title}</TitleContent>
          <TimeContent>{time}</TimeContent>
        </TitleWrapper>
      </CarouselItem>
    </ContentWrapper>
  );
};

export default FeatureReportCard;
