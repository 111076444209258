/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable-next-line */
'use client';
import { styled } from '@linaria/react';
import { sendGTM, GTMEventKey, CustomEventName } from '@fe-cnyes/fe-common-gtm';
import { memo } from 'react';

export type GtmEventType = {
  [GTMEventKey.EVENT_NAME]: CustomEventName;
  [GTMEventKey.SECTION]?: string;
  [GTMEventKey.CLICK_ITEM]?: string;
};
export interface ReadmoreProps {
  title?: string;
  url?: string;
  target?: '_self' | '_blank';
  gtmEvent?: GtmEventType;
}

const Link = styled.a`
  color: #e03f19;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: normal;

  &::after {
    content: url('/assets/icons/arrows/right.svg');
    margin-left: 3px;
  }
`;

export function ReadMore({
  title = '看更多',
  url = '',
  target = '_blank',
  gtmEvent,
}: ReadmoreProps) {
  const handleOnClick = () => {
    if (gtmEvent) {
      sendGTM(gtmEvent);
    }
  };
  return (
    <Link href={url} onClick={() => handleOnClick()} target={target}>
      {title}
    </Link>
  );
}

export default memo(ReadMore);
