import { GETQuotesBySymbols } from '@fe-news/api/quotes';

export const fetchQuotesBySymbolsKeys = async (symbols: string[] = ['']): Promise<Quote[]> => {
  /* 側邊欄列表只取得第一筆商品 */
  try {
    if (symbols.length === 0) return [];

    const response = await GETQuotesBySymbols(symbols);

    return response.data;
  } catch (error) {
    console.error('[API Error]: fetchQuotesBySymbolsKeys', error);
    return [];
  }
};
