/**
 * 傳進一個由 number 組成的 array，並隨機打亂順序。
 * @param {array[number]}
 * @returns {array[number]} - 打亂順序的 array
 */
export function arrayShuffle(array_: unknown[]) {
  const array = array_;
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

/**
 * @param {array[number]} array
 * @param {number} subGroupLength - 要劃分的群組個數
 * @returns {array[array[number]]} - 將 array 內以 n 個切分成多個 array
 */
export function arrayGroup(array: unknown[], subGroupLength: number) {
  let index = 0;
  const newArray = [];

  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }

  return newArray;
}
