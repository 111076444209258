// import Skeleton from 'react-loading-skeleton';
import { ImageSkeleton } from '@fe-common-ui/Skeleton';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Link from 'next/link';
import 'react-loading-skeleton/dist/skeleton.css';

interface FeatureReportProps {
  title: string;
  link: string;
  cardHeight?: number;
  cover: string;
  time: string;
  onClick?: () => void;
}

const IMAGE_WIDTH = 257;
const IMAGE_HEIGHT = 145;

const ContentWrapper = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    min-width: 300px;
  }
`;

const CarouselItem = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? height : 274)}px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const CoverWrapper = styled.div`
  width: 100%;
  min-height: ${IMAGE_HEIGHT}px;
  border-radius: 6px;
  overflow: hidden;

  :global(.footer) {
    margin-top: 0;
  }
`;

const NewsCover = styled.img`
  width: 100%;
  height: ${IMAGE_HEIGHT}px;
  border-radius: 6px;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 103px;
`;

const TitleContent = styled.p`
  width: 100%;
  height: 52px;
  font-size: 16px;
  line-height: 26px;
  color: #383838;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 6px;
`;

const TimeContent = styled.time`
  margin-top: 8px;
  font-size: 13px;
  letter-spacing: 1.06px;
  color: #848d97;
  text-align: right;
`;

const FeatureReportListCard = ({
  title = '',
  link = '',
  cardHeight,
  cover = '',
  time = '',
  onClick
}: FeatureReportProps) => {
  return (
    <ContentWrapper title={title} href={link} onClick={onClick}>
      <CarouselItem height={cardHeight}>
        <CoverWrapper>
          {cover ? (
            <NewsCover src={cover} alt='news cover' title={title} />
          ) : (
            <ImageSkeleton width={IMAGE_WIDTH} height={IMAGE_HEIGHT} />
          )}
        </CoverWrapper>
        <TitleWrapper>
          <TitleContent>{title}</TitleContent>
          <TimeContent>{time}</TimeContent>
        </TitleWrapper>
      </CarouselItem>
    </ContentWrapper>
  );
};

export default FeatureReportListCard;
