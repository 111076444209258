import { formatTime } from '@fe-common-utils/libs/time';
import {
  GETNews24hListResponse,
  GETNewsCategoriesResponse,
  GETNewsCategoryResponse,
  GETNewsListByTagsResponse,
  GETNotificationResponse,
  GETTagCloudResponse
} from '@fe-news/constants/news/type';
import { apiClient, processData, removeUndefinedKeys, tryCatch, wsClient } from './clients';

const DEFAULT_CATEGORIES = ['tw_stock', 'wd_stock', 'future', 'forex'];
const DEFAULT_NEWS_COUNTS_10 = 10;
const DEFAULT_NEWS_COUNTS_20 = 20;
const DEFAULT_NEWS_COUNTS_30 = 30;
const DEFAULT_PAGE = 1;
const DEFAULT_RESPONSE = {
  items: [],
  message: '',
  statusCode: 200
};

export const GETNewsOfMultipleCategory = async ({
  categories = DEFAULT_CATEGORIES,
  page = DEFAULT_PAGE,
  limit = DEFAULT_NEWS_COUNTS_10,
  startAt,
  endAt,
  version = 'v1',
  showOutsource = '1',
  isCategoryHeadline = '1'
}: {
  categories?: string | string[];
  page?: number;
  limit?: number;
  version?: string;
  startAt?: string;
  endAt?: string;
  showOutsource?: string;
  isCategoryHeadline?: string;
}) => {
  const parameters = Array.isArray(categories) ? categories : [categories];
  const apiUrl = `/media/api/${version}/newslist/category/${parameters.join(',')}`;

  let params: {
    page?: number;
    limit?: number;
    showOutsource?: string;
    startAt?: string;
    endAt?: string;
    isCategoryHeadline?: string;
  } = { page, limit };

  if (showOutsource === '1') params = { ...params, showOutsource };
  if (isCategoryHeadline === '1') params = { ...params, isCategoryHeadline };
  if (startAt && endAt) params = { ...params, startAt, endAt };
  return apiClient.get(apiUrl, params);
};

/**
 * 取得指定 ID 的財經新聞詳細內容
 * @param {number} id 新聞 ID
 * @param {string} version 新聞 API 版本，預設 v1
 * @returns {Promise}
 *
 * https://api.cnyes.com/media/api/v1/news/5468823?&status=no_token
 */
interface GETNewsByIdProperties {
  id: number;
  version?: string;
}

export const GETNewsById = async ({ id, version = 'v1' }: GETNewsByIdProperties) => {
  const apiUrl = `/media/api/${version}/news/${id}`;

  // 禁用 data cache，避免新聞內容重新編修後仍被快取，造成使用者看到錯誤資訊，每 10 分鐘重新取得新聞內容
  return apiClient.get(apiUrl, { status: 'no_token' }, { next: { revalidate: 600 } });
};

/**
 * /media/api/v1/news/:newsId
 * @export
 * @param {(Number|String)} newsId
 * @param {Number} relatedCount
 * @param {String} symbol    // symbol 2 means desktop, 4 means new reuters symbol for desktop
 * @param {String} oUrl
 * @param {Boolean} isLogin
 * @returns {Promise}
 */
interface GETv1NewsByIdProperties {
  newsId: string | number;
  isLogin?: boolean;
  relatedCount: number;
  symbol: string;
  oUrl: string;
}

export const GETv1NewsById = async ({
  newsId,
  isLogin = false,
  relatedCount,
  symbol,
  oUrl
}: GETv1NewsByIdProperties) => {
  const params: {
    relatedCount: number;
    symbol: string;
    oUrl: string;
    status?: string;
  } = {
    relatedCount,
    symbol,
    oUrl
  };

  if (!isLogin) {
    params.status = 'no_token';
  }

  return apiClient.get(`/media/api/v1/news/${encodeURIComponent(newsId)}`, params);
};

/**
 * /media/api/v1/newslist/24h
 * @export 主編精選
 * @returns {Promise}
 */
export const GETv1News24hList = () => {
  return apiClient.get(`/media/api/v1/newslist/24h`);
};

/**
 * /media/api/v1/topTopics/category/${catSlog}
 * @export 議題新聞
 * @returns {Promise}
 */
export const GETv1IssueNewsList = ({ limit }: { limit: number }) => {
  return apiClient.get(`/media/api/v1/newslist/category/topTopics`, { limit });
};

/**
 * /media/api/v1/newslist/${catSlug}
 * @export 人氣排行
 * @param {string} catSlug 類別新聞
 * @returns {Promise}
 */
export const GETv1NewsListCategory = ({
  catSlug = '',
  limit = DEFAULT_NEWS_COUNTS_10
}: {
  catSlug: string;
  limit?: number;
}) => {
  return apiClient.get(`/media/api/v1/newslist/${catSlug}`, { limit });
};

/**
 * /media/api/v1/newslist/categories?key=${key}
 * @export 議題新聞
 * @param {string} catSlug 類別新聞
 * @param {number} limit 限制筆數
 * @returns {Promise}
 */
export const GETv1NewsCategories = ({ key = '' }: { key?: string }) => {
  return apiClient.get(`/media/api/v1/newslist/categories`, {
    key
  });
};

/**
 * /media/api/v1/newslist/category/${catSlug}
 * @export 分類新聞
 * @param {string} catSlug 類別新聞
 * @param {number} limit 限制筆數
 * @returns {Promise}
 */
export const GETv1NewsCategory = ({
  catSlug = '',
  limit = DEFAULT_NEWS_COUNTS_10,
  category24hFlag,
  extraTag,
  startAt,
  endAt
}: {
  catSlug: string;
  limit?: number;
  category24hFlag?: 0 | 1;
  extraTag?: string;
  startAt?: number;
  endAt?: number;
}) => {
  let params: {
    limit: number;
    extraTag?: string;
    startAt?: number;
    endAt?: number;
    category24hFlag?: 0 | 1;
  } = {
    limit
  };

  if (typeof category24hFlag === 'number') params = { ...params, category24hFlag };
  if (extraTag) params = { ...params, extraTag };
  if (startAt && endAt) params = { ...params, startAt, endAt };

  return apiClient.get(`/media/api/v1/newslist/category/${catSlug}`, params, { next: { revalidate: 60 } });
};

/** 文字雲
 * @param kind 1(日), 7(周), 30(月)
 * @param limit 取得前n名排行. 預設為20
 * @export 熱門話題
 */
export function GETv1TagCloud({ kind, limit = DEFAULT_NEWS_COUNTS_20 }: { kind: number; limit?: number }) {
  const url = `/api/v1/tag/popular?kind=${kind}&limit=${limit}`;

  return apiClient.get(url);
}

/**
 * @export 專家觀點
 * @q {string} keyword
 * @page {number}
 * @startAt {string}
 * @endAt {string}
 * @returns {Promise}
 */
export function GETnewsListByTags({
  q = '',
  page = 1,
  startAt,
  endAt
}: {
  q: string;
  page: number;
  startAt: string;
  endAt: string;
}) {
  const params: { [x: string]: string | number } = {
    q,
    page,
    startAt,
    endAt
  };

  const url = '/media/api/v2/searchAllMatch';

  return apiClient.get(url, params);
}

/**
 * @export 鉅亨講座
 * @params {platform} : 1:app|2:web|3:morning
 * @params {sort} : asc|desc
 * @params {order} : start_datetime
 **/
export function GETv1NotificationLectures(id?: number) {
  const url = id ? `/media/api/v1/system/notificationLectures/${id}` : '/media/api/v1/system/notificationLectures';
  const params: { [x: string]: string | number } = {
    platform: 2,
    sort: 'asc',
    order: 'start_datetime'
  };

  return apiClient.get(url, params);
}

export function GETv1Notification(id?: number, channels?: number, messageType?: number) {
  const url = id ? `/media/api/v1/system/notification/${id}` : '/media/api/v1/system/notifications';
  // platform: {1:app|2:web|3:morning} messageType: {0:系統公告|1:產品行銷活動|2:廣告客戶}
  const params: { platform: number; channels?: number; messageType?: number } = { platform: 2 };

  if (channels) {
    params.channels = channels;
  }

  if (messageType) {
    params.messageType = messageType;
  }

  return apiClient.get(url, { params });
}

/**
 * 新聞文文末文字廣告
 * @param categoryId 新聞分類
 * @param param {limit: number}
 * @returns
 */
export function GETv1AdNewsSuffix(categoryId = 826, params: { limit: number }) {
  const url = `/media/api/v1/adNewsSuffixes/${categoryId}`;

  return apiClient.get(url, params);
}

/**
 * /media/api/v1/newslist/all
 * @export 所有新聞
 * @returns {Promise}
 */
export const GETv1AllNews = ({
  page = DEFAULT_PAGE,
  limit = DEFAULT_NEWS_COUNTS_30,
  startAt,
  endAt
}: {
  page?: number;
  limit?: number;
  startAt?: string;
  endAt?: string;
}) => {
  const url = `/media/api/v1/newslist/all`;
  let params: {
    page: number;
    limit: number;
    startAt?: string;
    endAt?: string;
  } = { page, limit };

  if (startAt && endAt) params = { ...params, startAt, endAt };

  return apiClient.get(url, params);
};

/**
 * Header Search 熱搜標籤
 * @returns Promise
 */
export const GETRecommendTags = () => {
  const url = '/api/v1/news/recommend/tags';

  return apiClient.get(url);
};

/**
 * 搜尋標籤新聞
 * @returns Promise
 * 因為 axios 本來就會對 query string 自動編碼，在這邊的參數反而會造成重複編碼問題
 * 所以另外利用 `paramsSerializer` 設定為手動處理編碼
 * 如果本來的 tag 就有空白，要加上 %20，保留原 tag，如: 'G4 EA,聯準會' -> 'G4%20EA,聯準會'
 */
export const GETv2Search = ({
  q: tags,
  page,
  version = 'v2',
  startAt,
  endAt,
  title
}: {
  q: string;
  page: number;
  version?: string;
  startAt?: string | number;
  endAt?: string | number;
  title?: string;
}) => {
  const startAtDate = typeof startAt === 'number' ? formatTime(Math.floor(startAt / 1000), 'YYYYMMDD') : startAt;

  const endAtDate = typeof endAt === 'number' ? formatTime(Math.floor(endAt / 1000), 'YYYYMMDD') : endAt;

  const url = `/media/api/${version}/search`;
  const params = {
    q: tags,
    page,
    startAt: startAtDate,
    endAt: endAtDate,
    title
  };

  return apiClient.get(url, removeUndefinedKeys(params));
};

/**
 * https://api.cnyes.com/media/api/v1/news/4803907?&status=no_token
 * 新聞內頁相關權證
 * "otherProduct": [
      "TWS:4961:STOCK",
      "TWS:TSE01:INDEX",
      "TWS:2330:STOCK",
      "TWS:2317:STOCK",
      "USS:AAPL:STOCK"
    ]
 * @returns Promise
 */
export const GETv2RelatedWarrants = async (otherProduct: string) => {
  if (!otherProduct) return null;

  const apiUrl = `/ws/api/v2/warrant/ratio/${otherProduct}`;

  return tryCatch(async function () {
    return await wsClient.get(apiUrl, {}, { cache: 'no-store' });
  });
};

/**
 * https://cnyesrd.atlassian.net/browse/ANUE-9362
 * 新聞內頁相關權證
 * "otherProduct": [
      "TWS:4961:STOCK",
      "TWS:TSE01:INDEX",
      "TWS:2330:STOCK",
      "TWS:2317:STOCK",
      "USS:AAPL:STOCK"
    ]
 * @returns Promise
 */
export const GETv3RelatedWarrants = async (otherProduct: string) => {
  if (!otherProduct) return null;

  const apiUrl = `/ws/api/v3/warrant/ratio/${otherProduct}`;

  return tryCatch(async function () {
    return await wsClient.get(apiUrl, {}, { cache: 'no-store' });
  });
};

// ----------------------------
// 重構
// ----------------------------

/**
 * /media/api/v1/newslist/${catSlug}
 * @export 人氣排行
 * @param {string} catSlug 新聞類別，固定就是 popular
 * @returns {Object} { all: [], tw_stock: [], wd_stock: [], future: [], forex: [] }
 */
export const fetchNewsPopular = async () => {
  const defaultValue = { all: [], tw_stock: [], wd_stock: [], future: [], forex: [] };

  return tryCatch(async function () {
    const data = await apiClient.get('/media/api/v1/newslist/popular');

    return processData(data, defaultValue);
  });
};

// ----------------------------
// 重構
// ----------------------------

/**
 * /media/api/v1/Pewslist/24h
 * @export 主編精選
 * @returns {Promise}
 */

export const GETNews24hList = async (): Promise<GETNews24hListResponse> => {
  const url = `/media/api/v1/newslist/24h`;

  try {
    const data = await apiClient.get(url);

    return data;
  } catch (error) {
    console.error('[API Error]: GETNews24hList', error);
    return {} as GETNews24hListResponse;
  }
};

/**
 * /media/api/v1/newslist/categories?key=${key}
 * @export 專題報導
 * @param {string} catSlug 類別新聞
 * @returns {Promise}
 */

type GETNewsCategoriesProps = {
  key?: string;
};

export const GETNewsCategories = async ({ key = '' }: GETNewsCategoriesProps): Promise<GETNewsCategoriesResponse> => {
  const url = `/media/api/v1/newslist/categories`;

  try {
    const data = await apiClient.get(url, { key });

    return data;
  } catch (error) {
    console.error('[API Error]: GETNewsCategories', error);
    return {} as GETNewsCategoriesResponse;
  }
};

/**
 * /api/v1/tag/popular?kind=${kind}&limit=${limit}
 * @param kind 1(日), 7(周), 30(月)
 * @param limit 取得前n名排行. 預設為20
 * @export 熱門話題
 */
export type GETTagCloudProps = { kind: number; limit: number };

export const GETTagCloud = async ({ kind, limit }: GETTagCloudProps): Promise<GETTagCloudResponse> => {
  const url = `/api/v1/tag/popular?kind=${kind}&limit=${limit}`;

  try {
    const data = await apiClient.get(url);

    return data;
  } catch (error) {
    console.error('[API Error]: GETTagCloud', error);

    return DEFAULT_RESPONSE;
  }
};

/**
 * @export 專家觀點
 * @q {string} keyword
 * @page {number}
 * @startAt {string}
 * @endAt {string}
 * @returns {Promise}
 */

type GETNewsListByTagsProps = {
  q: string;
  page: number;
  startAt: string;
  endAt: string;
};

export const GETNewsListByTags = async ({
  q = '',
  page = 1,
  startAt,
  endAt
}: GETNewsListByTagsProps): Promise<GETNewsListByTagsResponse> => {
  const url = '/media/api/v2/searchAllMatch';
  const params = { q, page, startAt, endAt };

  try {
    const data = await apiClient.get(url, params);

    return data;
  } catch (error) {
    console.error('[API Error]: GETNewsListByTags', error);
    return {} as GETNewsListByTagsResponse;
  }
};

/**
 * /media/api/v1/newslist/category/${catSlug}
 * @export 議題新聞
 * @param {string} catSlug 類別新聞
 * @param {number} limit 限制筆數
 * @returns {Promise}
 */

type GETNewsCategoryProps = {
  catSlug: string;
  limit?: number;
  category24hFlag?: 0 | 1;
  extraTag?: string;
  startAt?: number;
  endAt?: number;
};

export const GETNewsCategory = async ({
  catSlug = '',
  limit = DEFAULT_NEWS_COUNTS_10,
  category24hFlag,
  extraTag,
  startAt,
  endAt
}: GETNewsCategoryProps): Promise<GETNewsCategoryResponse> => {
  const url = `/media/api/v1/newslist/category/${catSlug}`;

  let params: {
    limit: number;
    extraTag?: string;
    startAt?: number;
    endAt?: number;
    category24hFlag?: 0 | 1;
  } = {
    limit
  };

  if (typeof category24hFlag === 'number') params = { ...params, category24hFlag };
  if (extraTag) params = { ...params, extraTag };
  if (startAt && endAt) params = { ...params, startAt, endAt };

  try {
    const data = await apiClient.get(url, params);
    return data;
  } catch (error) {
    console.error('[API Error]: GETNewsCategory', error);
    return {} as GETNewsCategoryResponse;
  }
};

/**
 * @export 鉅亨講座
 * @params {platform} : 1:app|2:web|3:morning
 * @params {sort} : asc|desc
 * @params {order} : start_datetime
 **/
export const GETNotificationLectures = async (id?: number): Promise<GETNotificationResponse> => {
  const url = id ? `/media/api/v1/system/notificationLectures/${id}` : '/media/api/v1/system/notificationLectures';
  const params: { [x: string]: string | number } = {
    platform: 2,
    sort: 'asc',
    order: 'start_datetime'
  };

  try {
    const data = await apiClient.get(url, params);
    return data;
  } catch (error) {
    console.error('[API Error GETNotificationLectures]:', error);
    return {} as GETNotificationResponse;
  }
};

export const GetNotification = async (
  id?: number,
  channels?: number,
  messageType?: number
): Promise<GETNotificationResponse> => {
  const url = id ? `/media/api/v1/system/notification/${id}` : '/media/api/v1/system/notifications';
  const params: { platform: number; channels?: number; messageType?: number } = { platform: 2 };

  if (channels) {
    params.channels = channels;
  }

  if (messageType) {
    params.messageType = messageType;
  }

  try {
    const data = await apiClient.get(url, { params });
    return data;
  } catch (error) {
    console.error('[API Error]: GetNotification', error);
    return {} as GETNotificationResponse;
  }
};
