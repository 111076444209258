/* eslint-disable @typescript-eslint/no-explicit-any */
import { removeUndefinedKeys, tryCatch, wsClient } from './clients';

/**
 * @param {string[]} symbols 商品symbol e.g. ["TWS:3324:STOCK", "USS:NVDA:STOCK"]
 * @returns {Promise} 商品價格,漲跌,漲跌幅...
 */
export const GETv1QuotesBySymbols = async (symbols: string[]) => {
  const url = `/ws/api/v1/quote/quotes/${symbols.join(',')}`;

  return tryCatch(async function () {
    return wsClient.get(url, {}, { next: { revalidate: 0 } });
  });
};

/**
 * @param {string} resolution 即時(1) / 分(5) /日(D) / 週(W) / 月(M)
 * @param {string} symbol 台股(TWS:0050:STOCK) / 台股興櫃(TWG:1260:STOCK) / 台股指數(TWS:TSE01:INDEX) / 陸股(CNS:601318:STOCK) / 港股(HKS:0700:STOCK) / 美股(USS:GOOGL:STOCK) / 國際指數(GI:SP500 ) / 期貨(GI:DJCIBR) / 外匯(FX:USDTWD)
 * @param {number} from timestamp
 * @param {number} to timestamp
 * @param {bool} quote api response include quote data or not
 * @param {bool} compress compress
 * @returns {Promise}
 */

export const GETchartingHistoryBySymbol = ({
  resolution,
  symbol,
  from,
  to,
  quote,
  compress
}: {
  resolution: string;
  symbol: string;
  from?: number;
  to?: number;
  quote?: string;
  compress?: number;
}) => {
  const url = '/ws/api/v1/charting/history';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {
    resolution,
    symbol,
    from,
    to,
    quote,
    compress
  };

  return wsClient.get(url, removeUndefinedKeys(params));
};

/**
 * @param {string} type 市場類別
 * @returns {Promise}
 */
export const GETv4MarketFocus = (params: { type?: string; column?: string }) => {
  const url = '/ws/api/v4/universal/quote';

  return wsClient.get(url, params);
};

/**
 * https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/633766431/API
 * @type {string} 跑馬燈 LMMR, 台股熱門股 LSTP, 國際熱門股 LSWP, 外匯熱門股 LCWP
 * @column 查詢欄位別
 * @page 分頁（非分頁查詢的type，後端忽略page參數）
 * @limit 筆數（非分頁查詢的type，後端忽略limit參數）
 * @returns {Promise}
 */
export function GETv2QuotesByType({
  type,
  column,
  page,
  limit
}: {
  type: string;
  column?: string;
  page?: number;
  limit?: number;
}) {
  const url = '/ws/api/v2/universal/quote';
  const params = { type, column, page, limit };

  return wsClient.get(url, removeUndefinedKeys(params));
}

const PARAMS_CONFIG_BY_COUNTRY = [
  {
    market: 'tws',
    classOneId: '110002',
    classTwoIds: ['120013,120014,120015', '120016']
  },
  { market: 'hks', classOneId: '410002', classTwoIds: ['420024', '420024'] },
  { market: 'cns', classOneId: '310002', classTwoIds: ['320007', '320008'] },
  { market: 'uss', classOneId: '210002', classTwoIds: ['220044'] }
];

/**
 * 依照獲得概念股元件資料 // 概念股排行在美股頁面時要改為第一個
 * @param {string} country  0 台股,1 港股,2 陸股,3 美股
 * @param {number} period  績效期間  0:年至今, 1:一日, 2:一周, 3:一月, 4:三月, 5:六月, 6:一年, 7:三年, 8:五年, 9:兩週, 10:十年
 * @param {number} theme  台股主題 0概念1集團 / 港股主題 0概念 / 陸股主題0滬股1深股
 * @param {string} sort   up 按照漲幅排序，down 按照跌幅排序
 * @returns
 */
export type RankingStockMarketType = 'tws' | 'hks' | 'cns' | 'uss';

export function GETv1StockRanking({
  period = 0,
  sort = 'up',
  country = 'tws',
  theme = 0
}: {
  period: number;
  sort: 'up' | 'down';
  country: string;
  theme: number;
}) {
  const url = '/ws/api/v1/concepts';
  const configIndex = PARAMS_CONFIG_BY_COUNTRY.findIndex(config => config.market === country);
  const params = {
    period,
    sort,
    market: country,
    classTwoIds: PARAMS_CONFIG_BY_COUNTRY[configIndex].classTwoIds[theme],
    classOneId: PARAMS_CONFIG_BY_COUNTRY[configIndex].classOneId
  };

  return wsClient.get(url, removeUndefinedKeys(params));
}

// ----------------------------
// 重構
// ----------------------------

/**
 * @param {string[]} symbols 商品symbol e.g. ["TWS:3324:STOCK", "USS:NVDA:STOCK"]
 * @returns {Promise} 商品價格,漲跌,漲跌幅...
 */

type GETQuotesBySymbolsResponseType = {
  data: Quote[];
  statusCode: number;
  message: string;
};

export const GETQuotesBySymbols = async (symbols: string[]): Promise<GETQuotesBySymbolsResponseType> => {
  const url = `/ws/api/v1/quote/quotes/${symbols.join(',')}`;

  try {
    const data = wsClient.get(url);

    return data;
  } catch (error) {
    console.error('[API Error]: GETQuotesBySymbols', error);
    return {
      data: [],
      statusCode: 200,
      message: ''
    };
  }
};

// 全休休市市場只顯示星期一到五
export const GETv1MarketCloseOverview = async (limit = 7) => {
  const url = `/ws/api/v1/global/countriesByDate?days=${limit}`;

  return wsClient.get(url);
};
